// extracted by mini-css-extract-plugin
export var mobileAppCardTechStack = "dr_BK";
export var mobileAppCardTechStack__card = "dr_BL";
export var mobileAppCardTechStack__description = "dr_BN";
export var mobileAppCardTechStack__iconsMobileDev = "dr_BP";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechJS = "dr_BT";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechKotlin = "dr_BR";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechReact = "dr_BS";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechSwift = "dr_BQ";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechTS = "dr_BV";
export var mobileAppCardTechStack__title = "dr_BM";