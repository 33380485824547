import { ExpertiseSectionMock } from '~/types/mock'

const MobileDevTechnologies: ExpertiseSectionMock[] = [
  {
    icon: 'mobileAppDevTechSwift',
    title: 'Swift mobile app development',
    description:
      'Swift is a powerful and intuitive programming language for creating incredible iOS, Mac, Apple TV, and Apple Watch apps.',
  },
  {
    icon: 'mobileAppDevTechKotlin',
    title: 'Kotlin mobile app development',
    description:
      'We like to use Kotlin for creating cross-platform and native apps. By the way, it is trusted by Netflix, Philips, Leroy Merlin, and other brands.',
  },
  {
    icon: 'mobileAppDevTechReact',
    title: 'React Native app development',
    link: '/services/react-native-app-development/',
    description:
      'React Native is an open-source JS framework that is the best choice for hybrid and multiplatform app development.',
  },
  {
    icon: 'mobileAppDevTechJS',
    title: 'Mobile app development with JavaScript',
    description:
      'We use TypeScript in app development as it improves code reliability and organization and better tooling support.',
  },
  {
    icon: 'mobileAppDevTechTS',
    title: 'TypeScript mobile app development',
    description:
      'We use TypeScript in app development as it improves code reliability and organization and better tooling support.',
  },
]

export default MobileDevTechnologies
