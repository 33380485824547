import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useMobileAppStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      clientImpact: file(
        relativePath: { eq: "avatars/clients/large-photo/client-impact.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      quoteCodicaEugenePhoto: file(
        relativePath: { eq: "avatars/FE-Eugene.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useMobileAppStaticQuery
