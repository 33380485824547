import { HomeSpecification } from '~/types/mock'

const MobileDevExpertise: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description:
      'Almost every smartphone user utilizes a mobile marketplace app to find a certain item. We help you build modern marketplaces to ensure as much profitability for your business solution as possible.',
    link: '/case-studies/kids-activities-service-marketplace/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description:
      'It is not a secret that mobile sales are leading in the eCommerce world. With Codica’s development services, our clients receive fast-loading and efficient platforms that boost sales.',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description:
      'Travel apps we build make traveling much more enjoyable for users as they need less time planning. Our team of professionals creates easy-to-use booking websites to drive traffic and sales.',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description:
      'App development for the automotive vertical allows you to monetize your business significantly because they have huge scope and implications for the future. We develop robust solutions for the vehicle rental and sale business.',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description:
      'It is not a secret that mobile sales are leading in the eCommerce world. With Codica’s development services, our clients receive fast-loading and efficient platforms that boost sales.',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description:
      'We are also professionals in building secure and intuitive websites for convenient hiring. These apps are the best helpers for recruiters to simplify engaging with job-seekers using mobile apps.',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description:
      'At Codica, we build engaging home rental platforms to disrupt the market. By choosing us for development, your end-users will obtain a seamless renting experience from the beginning to the finish.',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_17',
    title: 'Media',
    description:
      'Nowadays, more and more people access social and media networks from their mobile apps. We advise considering customers’ preferences - our mature specialists will make the top media platform for you that drives engagement.',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description:
      'At Codica, we build solutions to keep your brand at the forefront of users’ minds. With the quality mobile experiences we provide, you will improve patient loyalty, which impacts your revenue.',
    link: '/case-studies/dental-imaging-management-system/',
  },
]

export default MobileDevExpertise
