export interface ServicesCardWithTopBorderData {
  title: string
  description: string
  link?: string
}

const MobileDevPlatforms: ServicesCardWithTopBorderData[] = [
  {
    title: 'Native mobile app development',
    description:
      'Our Android and iOS app development experts create scalable and dependable apps.',
  },
  {
    title: 'HTML5 mobile app development',
    description:
      'We develop mobile apps with HTML5 that run well on any smartphone or tablet.',
  },
  {
    title: 'Cross platform mobile app development',
    link: '/services/cross-platform-app-development/',
    description:
      'We make apps that feel native and work well on different operating systems and platforms.',
  },
  {
    title: 'Cloud app development',
    link: '/services/cloud-application-development/',
    description:
      'At Codica, our specialists have strong skills and broad experience in cloud computing.',
  },
  {
    title: 'PWA development',
    link: '/services/progressive-web-apps-development/',
    description:
      'Codica is a top provider of progressive web apps, making reliable and secure solutions.',
  },
]

export default MobileDevPlatforms
