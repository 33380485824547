import { ExpertiseSectionMock } from '~/types/mock'

const MobileDevBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_92',
    title: 'Instant updating',
    description:
      'Custom mobile app development service delivers software that can scale and adapt to the changing needs of users.',
  },
  {
    icon: 'spriteIcon_88',
    title: 'Enhanced user experience',
    description:
      'You will get high conversion rates due to the improved user experience.',
  },
  {
    icon: 'spriteIcon_68',
    title: 'High-security level',
    description:
      'When developing a custom mobile app, the code belongs only to the customer and does not depend on third-party developers.',
  },
  {
    icon: 'spriteIcon_17',
    title: 'Fast launch to the market',
    description:
      'Creating a minimum viable product makes it fast to test the product on real users and develop the solution further if needed in the initial stage.',
  },
  {
    icon: 'spriteIcon_94',
    title: 'Advanced customization',
    description:
      'With custom mobile app development services, your ability to create features is only limited by your imagination. And business needs.',
  },
]

export default MobileDevBenefits
