import Title from '~/components/shared/Title/'
import { useAddClassNameWhenVisible } from '~/hooks/useAddClassNameWhenVisible'
import * as containerStylesSpriteIcons from '~/styles/modules/SpriteIcons.module.scss'
import { DataContentProps, WithOptional } from '~/types/helper'
import { ExpertiseSectionMock } from '~/types/mock'
import { cx } from '~/utils'

import * as containerStyles from './ServicesMobileAppStackCardSection.module.scss'

interface ServicesMobileAppStackCardSectionProps
  extends DataContentProps<WithOptional<ExpertiseSectionMock, 'title'>> {}

const ServicesMobileAppStackCardSection = ({
  dataContent,
}: ServicesMobileAppStackCardSectionProps) => (
  <div className="container container-md">
    <div className="row justify-content-center">
      {dataContent.map(({ icon, link, title, description }, index) => {
        const { ref } = useAddClassNameWhenVisible<HTMLDivElement>({
          targetClassName:
            containerStylesSpriteIcons[`spriteIconsWrapper__${icon}`],
          loadedClassName:
            containerStylesSpriteIcons.spriteIconsWrapper__sprite,
        })

        return (
          <div
            ref={ref}
            key={index}
            className={cx(
              containerStyles.mobileAppCardTechStack,
              'col-12 col-md-6 col-lg-4',
            )}
          >
            <div className={containerStyles.mobileAppCardTechStack__card}>
              <div
                className={cx(
                  containerStyles.mobileAppCardTechStack__iconsMobileDev,
                  containerStyles[
                    `mobileAppCardTechStack__iconsMobileDev_${icon}`
                  ],
                )}
              />

              <Title
                link={link}
                headingLevel="h3"
                className={containerStyles.mobileAppCardTechStack__title}
              >
                {title}
              </Title>

              <div
                className={containerStyles.mobileAppCardTechStack__description}
              >
                {description}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  </div>
)

export default ServicesMobileAppStackCardSection
