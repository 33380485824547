import { ExpertiseSectionMock } from '~/types/mock'

const MobileDevBuildProjects: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'Startups',
    description:
      'Do you often wonder about other startups - how they made it? If you want to build your own, you are in the right place - our passion is to help startups grow and scale up. We will guide you through the whole development journey, starting with idea research and ending with supporting your product after launch so you obtain the best outcomes possible.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'Small businesses',
    description:
      'Your own dedicated mobile app will be beneficial to your small business. Many users already enjoy the simplicity of mobile commerce, meaning plenty of people will gladly await your mobile app. By choosing us as a mobile development partner, you can ensure your target audience will obtain the user experiences and services they crave.',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'Mid-scale businesses',
    description:
      'At Codica, our experienced specialists help mid-sized businesses thrive. A high-quality mobile app can help current and future users in multiple ways. Mobile app development will benefit your business a lot. For example, a mobile app will provide customers with a better user experience, increase your brand awareness, help your business scale, and provide many other benefits as well.',
  },
  {
    icon: 'sprite3DIconSmall_19',
    title: 'Enterprises',
    description:
      'If you are already an established brand owner but have some issues with generating revenue or face customer losses - we are here to give a hand. We will identify your business’s weaknesses and do our best to improve your business’s performance, applying our expert skills and proven toolkit. Thus, your solution will stay in the game and be the users’ favorite on the market.',
  },
]

export default MobileDevBuildProjects
