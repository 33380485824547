import { Link } from 'gatsby'

import { ServicesProcessMock } from '~/types/mock'

const MobileDevProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: (
      <>
        <Link to="/services/product-discovery/" key="keyTitleProductDiscovery">
          Product discovery
        </Link>
      </>
    ),

    descr:
      'Stage of collecting and documenting requirements. The client gets a detailed technical proposal for software development.',
  },
  {
    num: '02',
    title: 'UI/UX design',
    descr:
      'Based on the research we have done at project discovery, we draw up the entire website focusing on the easiest user journey.',
  },
  {
    num: '03',
    title: 'Development',
    descr:
      'Along with the design, development is underway. Developers code the app using an agile approach and the best tech practices.',
  },
  {
    num: '04',
    title: 'Testing',
    descr:
      'The process comprises planning, test developing, running of the solution, and analysis of the obtained results.',
  },
  {
    num: '05',
    title: 'Maintenance & support',
    descr:
      'This is a must to ensure that the application still functions correctly according to the latest technology updates.',
  },
]

export default MobileDevProcess
