import { ExpertiseSectionMock } from '~/types/mock'

const MobileDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'Mobile consulting',
    description:
      'Our team will help you choose the right strategies, practices, and trends for a lucrative app.',
  },
  {
    icon: 'sprite3DIcon_5',
    title: 'Mobile app prototyping',
    description:
      'Experts at Codica build mobile app prototypes that show how a project will operate.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'Mobile app design',
    description:
      'Our designers provide top usability, engagement, and overall great app user experience.',
  },
  {
    icon: 'sprite3DIcon_13',
    link: '/services/mvp-app-development/',
    title: 'MVP app development',
    description:
      'With a minimum viable product, we test the early app’s version and collect user reviews.',
  },
  {
    icon: 'sprite3DIcon_11',
    title: 'Mobile application development',
    description:
      'We provide mobile app development that keeps up with the relevant needs of your industry.',
  },
  {
    icon: 'sprite3DIcon_19',
    title: 'Mobile app testing (QA)',
    description:
      'Our experts conduct high-level software quality assurance to ensure your app functions smoothly.',
  },
  {
    icon: 'sprite3DIcon_20',
    title: 'Maintenance and support',
    description:
      'We provide mobile app development that keeps up with the relevant needs of your industry.',
  },
]

export default MobileDevServices
