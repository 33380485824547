import { ExpertiseSectionMock } from '~/types/mock'

const MobileDevKeyPrinciples: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_89',
    title: 'Smart',
    description:
      'With our best practices and tools, we can avoid tech risks while writing the code and make it resistant to faults.',
  },
  {
    icon: 'spriteIcon_63',
    title: 'Secure',
    description:
      'When writing code, we follow strict security principles to prevent vulnerabilities and build a reliable and secure IT infrastructure.',
  },
  {
    icon: 'spriteIcon_86',
    title: 'Stable',
    description:
      'Our software developers build a powerful and stable server-side for your digital solution, so it runs quickly under any type of user load.',
  },
  {
    icon: 'spriteIcon_60',
    title: 'Usable',
    description:
      'As a firm skilled in mobile application development services, we make aesthetically pleasing and usable interfaces.',
  },
]

export default MobileDevKeyPrinciples
