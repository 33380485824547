import { FAQ } from '~/types/data-array'

const MobileDevFAQ: FAQ[] = [
  {
    question: 'How much does mobile app development cost?',
    answer:
      'Mobile application development service costs can vary depending on many factors. Hire mobile app development company in Eastern Europe, and the average cost will be $20,000-$50,000, whereas, in the USA, the price will be higher.',
  },
  {
    question: 'How long does it take to develop a mobile app?',
    answer:
      'The average time of application development for mobile devices with discovery, design, development, and other pre-launch steps is around 7-12 months.',
  },
  {
    question: 'What language is used for development of the mobile app?',
    answer:
      'At the Codica agency, we prefer JavaScript, TypeScript, and Kotlin to build modern and reliable mobile apps.',
  },
  {
    question: 'What is cross-platform mobile app development?',
    answer:
      'Cross-platform development aims at building a single app that runs identically on some platforms. These applications can operate on both Android and iOS devices, as well as other platforms.',
  },
  {
    question: 'What is the best mobile app development software to use?',
    answer:
      'As an experienced top mobile development company, we choose for mobile development JavaScript, TypeScript, React Native, Swift, and Kotlin.',
  },
]

export default MobileDevFAQ
