// extracted by mini-css-extract-plugin
export var clientQuotesMobileDev = "B_fg";
export var devBenefitBussinesMobileDev = "B_fc";
export var domainExpertiseMobileDev = "B_d7";
export var mobileAppDevTechnologies = "B_d4";
export var mobileAppDevTechnologies__wrapper = "B_d5";
export var ourKeyPrinciplesMobileDev = "B_fb";
export var serviceMobileTestimonialPost = "B_d9";
export var servicesCardBorderIconMobileDevSection = "B_d2";
export var servicesCardWithTopBorderSectionMobileApp = "B_d3";
export var servicesProcessMobileDevSection = "B_d8";
export var successStoriesMobileSection = "B_ff";
export var weBuildProjectsDifferentSection = "B_d6";
export var whyChooseServicesMobile = "B_fd";